import axios from "axios";

let refresh = false;

axios.interceptors.response.use(resp => resp, async error => {
  if (error.response.status === 401 && !refresh) {
     refresh = true;
     console.log(localStorage.getItem('refresh_token'))
     const response = await   
           axios.post('http://localhost:8000/token/refresh/', 
            { refresh:localStorage.getItem('refresh_token')}, 
            { headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('access_token'),
                'Content-Type': 'application/json',
                'accept': 'application/json'
            } },
            { withCredentials: true });
  }
    if (error.response.status === 200) {
       axios.defaults.headers.common['Authorization'] = `Bearer 
       ${error.response.data['access']}`;
       localStorage.setItem('access_token', error.response.data.access);
       localStorage.setItem('refresh_token', error.response.data.refresh);
       return axios(error.config);
    }
  
refresh = false;
return error;
});