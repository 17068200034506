import { Logout } from './journalist_components/Logout';
import { Login } from './journalist_components/login';
import Register from './journalist_components/Register';

import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";

import JournalistHome from './journalist_components/JournalistHome';
import Article from './journalist_components/Article';
import NewArticle from './journalist_components/NewArticle';
import Profile from './journalist_components/Profile';
import EditArticle from './journalist_components/EditArticle';
import ReaderHomepage from './reader_components/ReaderHomepage';
import ReaderArticle from './reader_components/ReaderArticle';
import ArticleList from './journalist_components/ArticleList';
import AboutPage from './reader_components/AboutPage';


const App = () => {

  return (
    <>
            {/* This is the alias of BrowserRouter i.e. Router */}
            <Router>
                <Routes>
                    {/* journalist routes */}
                    <Route exact path="/journalist/home" element={<ArticleList />}/>
                    <Route path="/login" element={<Login/>}/>
                    <Route path="/logout" element={<Logout/>}/>
                    <Route path="/register" element={<Register/>}/>
                    <Route path="/newArticle" element={<NewArticle/>}/>
                    <Route path="/article/:id" element={<Article />} />
                    <Route path="/article/edit/:id" element={<EditArticle />} />
                    <Route path="/user/:id" element={<Profile />} />

                    {/* reader routes */}
                    <Route exact path="/" element={<ReaderHomepage />}/>
                    <Route path="*" element={<Navigate to="/reader/home" />} />
                    <Route path="/reader/home" element={<ReaderHomepage />} />
                    <Route path="/about" element={<AboutPage />} />
                    <Route path="/article/reader/:id" element={<ReaderArticle />} />

                </Routes>
            </Router>
        </>
  );
}

export default App;
