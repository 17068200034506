import React, { useEffect, useState } from "react";
import { JournalistHeader } from "./JournalistHeader";
import { useParams } from "react-router-dom";
import axios from 'axios'
import styles from './Style.module.css'
import {
  Box,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Grid,
  Grommet,
  Heading,
  Image,
  Stack,
  Text,
} from 'grommet'

import { Link } from "react-router-dom";


const Profile = () => {
    const [has_pending, set_has_pending] = useState(true)
    const [has_published, set_has_published] = useState(true)
    const {id} = useParams();
    const [user, SetUser] = useState({})
    const [articles, SetArticles] = useState([])
    const [publishedArticles, setPublishedArticles] = useState([])
    const [pendingArticles, setPendingArticles] = useState([])
    const [archivedArticles, setArchivedArticles] = useState([])
    const token = localStorage.getItem('access_token');

    useEffect(() => {
        if(localStorage.getItem('access_token') === null){                   
            window.location.href = '/login'
        }
        const fetch = async () => {
          try {
            const { data } = await axios.get(`https://leo-api.xyz/api/users/${id}/`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'application/json',
                  }
                });
                SetUser(data)

              const { articleData } = axios.get(`https://leo-api.xyz/api/users/${id}/getArticles/`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'application/json',
                  }
                }).then(response => {
                  const parsedArticles = JSON.parse(response.data.articles)
                  SetArticles(parsedArticles)
                  setPendingArticles(parsedArticles.filter(article => article.fields.published === false 
                                                                        && article.fields.archivedPublished === false));

                  setPublishedArticles(parsedArticles.filter(article => article.fields.published === true 
                                                                        || article.fields.archivedPublished === true));

                });
              

          } catch (err) {
            console.error(err);
            }
        };

        fetch();


      }, []);

    return (
        <div>
            <JournalistHeader />
            <Grommet>
                <Box width={'xlarge'} pad={{ horizontal: 'large', vertical: 'none' }}>
                  <Heading weight='bold'  level={2}>
                      {user.username}
                  </Heading>
                </Box>

            {//----------------------------------Published--------------------------------------------------------
            }
            {
              publishedArticles.length > 0 ? 
                <Box width={'xlarge'} pad={{ horizontal: 'large', vertical: 'none' }}>
                  <Heading weight='bold'  level={3}>
                      Published Articles
                  </Heading>
                </Box>
                :
                <></>
            }

            <Grid
              pad={{ horizontal: 'large', vertical: 'none' }}
              columns={['1/3','1/3', '1/3']}
              //gap={'small'}
              fill
              >
                    {publishedArticles.map((article) => (
                        <Box key={article.pk} elevation="small" height='auto' border={{side: 'all', color: "white"}}>
                            <Link to={`/article/reader/${article.pk}`} style={{ textDecoration: 'none' }}>
                                <Box>
                                    <Stack anchor="bottom-left">
                                        <Image src={`https://leo-api.xyz/media/${article.fields.image_url}`} style={{ width: '100%', height: '300px', objectFit: 'cover' }} />
                                        <Card background={{opacity:'strong', color: 'black'}}>    
                                            <Box pad="small">
                                                <CardHeader>
                                                    <Text size='xlarge' weight='bold' color="#47B0EC">
                                                        {article.fields.headline}
                                                    </Text>
                                                </CardHeader>
                                            </Box>
                                        </Card>
                                    </Stack>
                                    <Box pad={'medium'}>
                                        <Text size='large' weight={'lighter'} color="black">
                                            {article.fields.subtitle}
                                        </Text>
                                    </Box>
                                </Box>
                            </Link>
                        </Box>
                    ))}
                </Grid>


              {//----------------------------------Pending--------------------------------------------------------
            }
              {pendingArticles.length > 0 ? 
                  <Box width={'xlarge'} pad={{ horizontal: 'large', vertical: 'none' }}>
                    <Heading weight='bold'  level={3}>
                        Pending Articles
                    </Heading>
                  </Box>
                :
                <></>

              }
                
                <Grid
              pad={{ horizontal: 'large', vertical: 'none' }}
              columns={['1/3','1/3', '1/3']}
              fill
              >
                  {pendingArticles.map((article) => (
                      <Box key={article.pk} elevation="small" height='auto' border={{side: 'all', color: "white"}}>
                          <Link to={`/article/${article.pk}`} style={{ textDecoration: 'none' }}>
                              <Box>
                                  <Stack anchor="bottom-left">
                                      <Image src={`https://leo-api.xyz/media/${article.fields.image_url}`} style={{ width: '100%', height: '300px', objectFit: 'cover' }} />
                                      <Card background={{opacity:'strong', color: 'black'}}>    
                                          <Box pad="small">
                                              <CardHeader>
                                                  <Text size='xlarge' weight='bold' color="#47B0EC">
                                                      {article.fields.headline}
                                                  </Text>
                                              </CardHeader>
                                          </Box>
                                      </Card>
                                  </Stack>
                                  <Box pad={'medium'}>
                                      <Text size='large' weight={'lighter'} color="black">
                                          {article.fields.subtitle}
                                      </Text>
                                  </Box>
                              </Box>
                          </Link>
                      </Box>
                  ))}
              </Grid>

              {//----------------------------------New User--------------------------------------------------------
            }
            {pendingArticles.length === 0 && publishedArticles.length === 0 ? 
                <Box align="center" justify="center">
                  <Heading weight='bold'  level={2}>
                      You haven't written any articles yet
                  </Heading>
                  <Heading weight='bold'  level={3}>
                      Click the plus in the top right corner to get started!
                  </Heading>
                </Box>
              :
              <></>

            }

            </Grommet>
            
        </div>
    )
};
 
export default Profile;

