import React, { useEffect, useState } from "react";
import { ReaderHeader } from "./ReaderHeader";
import { useParams } from "react-router-dom";
import axios from 'axios'
import styles from '../journalist_components/Style.module.css'
import {
  Box,
  Card,
  CardHeader,
  Grid,
  Grommet,
  Heading,
  Stack,
  Image,
  Text,
} from 'grommet'

import { Link } from "react-router-dom";


const ReaderHomepage = () => {
    const {id} = useParams();
    const [user, SetUser] = useState({})
    const [articles, setArticles] = useState([])
    const [sortedArticles, setSortedArticles] = useState([])
    const [topArticles, setTopArticles] = useState([])
    const [remainingArticles, setRemainingArticles] = useState([])

    const token = localStorage.getItem('access_token');

    useEffect(() => {
        const fetch = async () => {
          try {

              const { articleData } = axios.get(`https://leo-api.xyz/api/reader/articles/`, {
                headers: {
                    'Content-Type': 'application/json',
                  }
                }).then(response => {
                    const parsedArticles = JSON.parse(response.data.articles)
                    setArticles(parsedArticles)

                    console.log(response.data)
                    
                    //sort by number of endorsements
                    const sorted = [...parsedArticles].sort((a, b) => b.fields.priority - a.fields.priority  )
                    setSortedArticles(sorted)

                });
              

          } catch (err) {
            console.error(err);
            }
        };

        fetch();

      }, []);

      useEffect(() => {
        // Separate the first three articles for the top row after sortedArticles state is updated
        if (sortedArticles.length > 0) {
          setTopArticles(sortedArticles.slice(0, 3));
          setRemainingArticles(sortedArticles.slice(3));
        }
      }, [sortedArticles]);

    return (
        <div>
            <Grommet>
                <ReaderHeader />
                <Grid
                columns={['2/3', '1/3']} // First column for large image, second column for stacked smaller images
                fill
                >
                    {/* First large image */}
                    {topArticles[0] && (
                            <Box elevation="small"  border={{side: 'all', color: "white"}} >
                                <Link to={`/article/reader/${topArticles[0].pk}`} style={{ textDecoration: 'none' }}>
                                    <Stack anchor="bottom-left">
                                        <Image src={`https://leo-api.xyz/media/${topArticles[0].fields.image_url}`} style={{ width: '100%', height: '600px', objectFit: 'cover' }}/>
                                            <Card background={{opacity:'strong', color: 'black'}}>    
                                                <Box pad="small">
                                                    <CardHeader pad={'medium'}>
                                                        <Heading margin='none' weight='bold' color={"#47B0EC"} level={3}>
                                                            {topArticles[0].fields.headline}
                                                        </Heading>
                                                    </CardHeader>
                                                </Box>
                                            </Card>
                                    </Stack>
                                        
                                </Link>
                            </Box>
                    )}
                    
                    {/* Two smaller images stacked */}
                    <Box>
                        {topArticles.slice(1).map((article, index) => (
                            <Box key={article.pk} elevation="small" height='auto' border={{side: 'all', color: "white"}}>
                                    <Link to={`/article/reader/${article.pk}`} style={{ textDecoration: 'none' }}>
                                        <Box>
                                            <Stack anchor="bottom-right">
                                                <Image src={`https://leo-api.xyz/media/${article.fields.image_url}`}  style={{ width: '100%', height: '300px', objectFit: 'cover' }}/>
                                                <Card background={{opacity:'strong', color: 'black'}}>    
                                                    <Box pad="small">
                                                        <CardHeader>
                                                            <Text size='xlarge' weight='bold' color="#47B0EC">
                                                                {article.fields.headline}
                                                            </Text>
                                                        </CardHeader>
                                                    </Box>
                                                </Card>
                                            </Stack>
                                            
                                        </Box>
                                    </Link>
                            </Box>
                        ))}
                    </Box>
                </Grid>

                <Grid
                //pad="medium"
                columns={['1/3','1/3', '1/3']}
                //gap={'small'}
                fill
                >
                    {/* All remaining images */}
                    {remainingArticles.map((article) => (
                        <Box key={article.pk} elevation="small" height='auto' border={{side: 'all', color: "white"}}>
                            <Link to={`/article/reader/${article.pk}`} style={{ textDecoration: 'none' }}>
                                <Box>
                                    <Stack anchor="bottom-left">
                                        <Image src={`https://leo-api.xyz/media/${article.fields.image_url}`} style={{ width: '100%', height: '300px', objectFit: 'cover' }} />
                                        <Card background={{opacity:'strong', color: 'black'}}>    
                                            <Box pad="small">
                                                <CardHeader>
                                                    <Text size='xlarge' weight='bold' color="#47B0EC">
                                                        {article.fields.headline}
                                                    </Text>
                                                </CardHeader>
                                            </Box>
                                        </Card>
                                    </Stack>
                                    <Box pad={'medium'}>
                                        <Text size='large' weight={'lighter'} color="black">
                                            {article.fields.subtitle}
                                        </Text>
                                    </Box>
                                </Box>
                            </Link>
                        </Box>
                    ))}
                </Grid>
            
            </Grommet>
            
        </div>
    )
};
 
export default ReaderHomepage;

