import React, { useEffect, useState } from "react";
import { JournalistHeader } from "./JournalistHeader";
import { useParams } from "react-router-dom";
import axios from 'axios'
import styles from './Style.module.css'
import {
    Box,
    Button,
    Card,
    Form,
    FormField,
    Grommet,
    Heading,
    Image,
    Paragraph, 
    Text,
    TextInput,
  } from 'grommet'

  import { Deploy, Like, LikeFill } from "grommet-icons";
  import { Link } from "react-router-dom";

const Article = () => {
    const {id} = useParams();
    const [hasEndorsed, SetHasEndorsed] = useState(false);
    const [currentArticle, SetCurrentArticle] = useState({});
    const [comments, SetComments] = useState([]);
    const [sources, SetSources] = useState([]);
    const [author, SetAuthor] = useState({});
    const [userID, SetUserID] = useState();
    const [replyingID, setReplyingID] = useState();
    const [replyText, setReplyText] = useState("");
    const [buttonText, setButtonText] = useState(
        `Endorsements`
    )
    
    const [formData, setFormData] = useState({
        article: '',
        commentText: '',
        commenters: '',
        likes: 0,
        parent: '',

    });

    const onChange = e => setFormData({ ...formData, [e.target.name]: e.target.value });

    const { article, commentText, commenters, likes, parent } = formData;

    const token = localStorage.getItem('access_token');

    useEffect(() => {
        if(localStorage.getItem('access_token') === null){                   
            window.location.href = '/login'
        }
        const fetch = async () => {
          try {
            const { data } = await axios.get(`https://leo-api.xyz/api/articles/${id}/`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'application/json',
                  }
                });
            SetCurrentArticle(data);
            if(data.likes_count === 1) {
                setButtonText(`${data.likes_count} Endorsement`)
            }
            else {
                setButtonText(`${data.likes_count} Endorsements`)
            }

            axios.get(`https://leo-api.xyz/api/articles/${id}/comments/`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'application/json',
                  }
                }).then(response => {
                    SetComments(response.data);
                });
            
            axios.get(`https://leo-api.xyz/api/articles/${id}/sources/`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'application/json',
                    }
                }).then(response => {
                    SetSources(response.data);
                });

            axios.post(`https://leo-api.xyz/api/articles/${data.id}/checkLikes/`,{}, {
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('access_token'),
                'Content-Type': 'application/json',
                'accept': 'application/json'
              }
            }, )
            .then(response => {
                if (response.data.status === 'not liked') {
                    SetHasEndorsed(false)
                } else {
                    SetHasEndorsed(true)
                }

            })
            
            axios.get(`https://leo-api.xyz/api/users/${data.authors}/`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'application/json',
                  }
                }).then (response => {
                    SetAuthor(response.data)
                })
                
            axios.get(`https://leo-api.xyz/api/user/`, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('access_token')}`,
                    'Content-Type': 'application/json',
                    }
                }).then (response => {
                    SetUserID(response.data.user)
                });
            
            
                
            
          } catch (err) {
            console.error(err);
          }
        };

        fetch();

      }, []);

      const handleCommentLike = (commentID) => {
        const token = localStorage.getItem('access_token');
        if(!token) {
            console.log("SIGN IN FIRST!")
        }

        axios.post(`https://leo-api.xyz/api/comment/${commentID}/like/`,{}, {
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('access_token'),
                'Content-Type': 'application/json',
                'accept': 'application/json'
              }
            }, )
            .then(response => {
                //console.log(`comment like response: ${response.data.status}`)
                reloadComments()

            })
            .catch(error => {
                console.error('There was an error liking the post!', error);
            });
    };

    const reloadComments = () => {
        axios.get(`https://leo-api.xyz/api/articles/${id}/comments/`, {
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json',
                }
            }).then(response => {
                SetComments(response.data);
            });
    };

    const reloadArticle = () => {
        axios.get(`https://leo-api.xyz/api/articles/${id}/`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'application/json',
                  }
                }).then(response => {
                    SetCurrentArticle(response.data);
                    if(response.data.likes_count === 1) {
                        setButtonText(`${response.data.likes_count} Endorsement`)
                    }
                    else {
                        setButtonText(`${response.data.likes_count} Endorsements`)
                    }
                });
    };

    const handleArticleLike = (articleID) => {
        const token = localStorage.getItem('access_token');
        if(!token) {
            console.log("SIGN IN FIRST!")
        }

        axios.post(`https://leo-api.xyz/api/articles/${articleID}/like/`,{}, {
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('access_token'),
                'Content-Type': 'application/json',
                'accept': 'application/json'
              }
            }, )
            .then(response => {
                SetHasEndorsed(!hasEndorsed)
                //setButtonText(endorsementText)
                reloadArticle();

            })
            .catch(error => {
                console.error('There was an error liking the post!', error);
            });
    };

    const deleteArticle = (articleID) => {
        const token = localStorage.getItem('access_token');
        if(!token) {
            console.log("SIGN IN FIRST!")
        }

        axios.delete(`https://leo-api.xyz/api/articles/${articleID}/`, {
        headers: {
            'Authorization': 'Bearer ' + localStorage.getItem('access_token'),
            'Content-Type': 'application/json',
            'accept': 'application/json'
            }
        }, )
        .catch(error => {
            console.error('There was an error deleting the post!', error);
        });

        //window.location.href = `/journalist/home`
    };

    const submitComment = async e => {
        if (!token) {
            alert('You need to login first');
            return;
        }

        e.preventDefault();

        const newComment = {
            article,
            commentText,
            commenters,
            likes,
            parent,
        };

        let form_data = new FormData();

        console.log(`article: ${currentArticle.id}`);
        form_data.append("article", currentArticle.id);

        console.log(`commentText: ${newComment.commentText}`)
        form_data.append("commentText", newComment.commentText);



        try {
            const res = await axios.post('https://leo-api.xyz/api/comment/', form_data, {
                headers: {
                    Authorization: `Bearer ${token}`,
                    "Content-Type": "multipart/form-data",
                  }
            })
            
            
            const res2 = await axios.post(`https://leo-api.xyz/api/comment/${res.data.id}/addAuthor/`,{}, {
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('access_token'),
                'Content-Type': 'application/json',
                'accept': 'application/json'
            }
            }, )
            
            
        } catch (err) {
            console.error(err);
        }
        //reloadComments();
        window.location.href = `/article/${currentArticle.id}`
    };
    const submitReply = async e => {
        if (!token) {
            alert('You need to login first');
            return;
        }

        e.preventDefault();

        const newReply = {
            replyText,
        };

        let form_data = new FormData();

        console.log(`article: ${currentArticle.id}`);
        form_data.append("article", currentArticle.id);

        console.log(`commentText: ${newReply.replyText}`)
        form_data.append("commentText", newReply.replyText);

        form_data.append("parent", replyingID);


        try {
            const res = await axios.post('https://leo-api.xyz/api/comment/', form_data, {
                headers: {
                    Authorization: `Bearer ${token}`,
                    "Content-Type": "multipart/form-data",
                  }
            })
            
            
            const res2 = await axios.post(`https://leo-api.xyz/api/comment/${res.data.id}/addAuthor/`,{}, {
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('access_token'),
                'Content-Type': 'application/json',
                'accept': 'application/json'
            }
            }, )
            
            
        } catch (err) {
            console.error(err);
        }
        //reloadComments();
        window.location.href = `/article/${currentArticle.id}`
    };

    let endorsementText = ""
    if(currentArticle.endorsements === 1) {
        endorsementText = `${currentArticle.likes_count} Endorsement`;
    }
    else {
        endorsementText = `${currentArticle.likes_count} Endorsements`;
    }

    return (
        <div>
            <JournalistHeader />
            <Grommet>

                <Box align="center" pad={{ horizontal: 'large', vertical: 'small' }}>
                    <Heading weight='bold' textAlign="center" level={2} pad="none">
                        {currentArticle.headline}
                    </Heading>
                    <Box pad={{ bottom:'medium'}} width={'large'}>
                        <Text size="large" textAlign="center" weight={'lighter'}>
                            {currentArticle.subtitle}
                        </Text>
                    </Box>
                    {
                        author.username ? <Text>
                                            By: {author.username}  
                                        </Text>
                                        : ""
                    }
                    <Box pad={{ top:'medium'}} width={'large'}>
                        <Text size="small" textAlign="center" color="dark-6">
                            {new Date(currentArticle.written_on).toLocaleDateString('en-US', {
                                year: 'numeric',
                                month: 'long',
                                day: 'numeric'
                            })}
                        </Text>
                    </Box>
                    <Box width={"xlarge"} pad={{ horizontal: 'xlarge', vertical: 'large' }}> 
                        <Image fill src={currentArticle.image_url}/>
                    </Box>

                    <Box width={"xlarge"} >
                        <Paragraph className={styles.displayLinebreak} fill='true' margin={{ horizontal: 'xlarge', vertical: 'large' }}>
                            {currentArticle.body}
                        </Paragraph>
                    </Box>
                    <Box width={"large"} border="bottom"></Box>
                    <Box pad="small"></Box>

                    <Card background="light-1" >
                            <Box pad = "small" direction="row" flex="grow">
                                    <Box pad={{ horizontal: 'small', vertical: 'none' }} border="right">
                                        { buttonText } 
                                    </Box>
                                    <Box pad={{ horizontal: 'small', vertical: 'none' }}>
                                        <Button onClick={() =>{
                                            handleArticleLike(currentArticle.id)
                                            }
                                        }>
                                            { 
                                                hasEndorsed ? <Deploy color="#47B0EC" size="medium"/> : <Deploy size="medium"/>
                                            }
                                        </Button>
                                    </Box>
                            </Box>
                        </Card>
                        <Box pad={{top:'medium',bottom: 'large', horizontal:'medium'}}>
                            {
                                userID==author.id ? <Button style={{color: 'red', textDecoration: 'none' }} onClick={() => deleteArticle(currentArticle.id)}> Delete </Button> : ""
                            }
                        </Box>
                    
                </Box>

                <Box align="center" pad={{ horizontal: 'large', vertical: 'none' }}>
                    <Box width={"xlarge"} >
                        <Heading weight='bold' level={2} pad="none" margin={{ horizontal: 'xlarge', vertical: 'none' }}>
                            {"Sources"}
                        </Heading>
                    </Box>

                    {sources.map((source, index) => (
                        <Box width={"xlarge"} pad={{ horizontal: 'xlarge', vertical: 'small' }} key={index}>
                            <Text >
                                {`${source.title} by ${source.author}`}
                            </Text>
                            { source.URL === null ? <></>
                                :
                                <div>
                                    <a href={source.URL} target="_blank" rel="noopener noreferrer"> {source.URL}</a>
                                    
                                </div>
                            }
                        </Box>
                    ))}
                </Box>
                
                


                <Box align="center" pad={{ horizontal: 'large', vertical: 'none' }}>
                    <Box width={"xlarge"} >
                        <Heading weight='bold' level={2} pad="none" margin={{ horizontal: 'xlarge', vertical: 'none' }}>
                            {"Discussion"}
                        </Heading>
                    </Box>

                    <Box width={"xlarge"} pad={{ horizontal: 'xlarge', vertical: 'small' }}>
                        <Form onSubmit={submitComment}>
                            <Box>
                                <FormField name="comment" htmlFor="comment" width={"xlarge"}>
                                    <TextInput id="textinput-id" name="commentText" value={commentText}
                                    onChange={onChange} placeholder="Add a comment" required/>
                                </FormField>
                            </Box>
                            <Box direction="row" justify='end' gap="medium">
                                <Button type="submit" primary color="#47B0EC" >
                                    <Box pad={"small"} gap="small" direction="row">
                                        <Text color={"white"}>
                                            Comment
                                        </Text>
                                    </Box>
                                </Button>
                            </Box>
                        </Form>
                    </Box>
                    {comments.map((comment, index) => (

                        <Box width={"xlarge"} pad={{ horizontal: 'xlarge', vertical: 'small' }} key={index}>
                            <Box elevation="medium" pad='medium' round='small'>
                                <Text weight={'bold'}> {comment.commenters[0].username} </Text>
                                <Text key={index}>{comment.commentText}</Text>
                                <Box direction="row" gap='small' pad={{top: 'small'}}>
                                    <Button onClick={() =>{
                                            handleCommentLike(comment.id);
                                        }
                                    }>
                                         {
                                            comment.likes.some(user => user.id === userID) ? <LikeFill size='medium' color="#47B0EC" ></LikeFill>   : <Like size='medium' color="#ADADAD" ></Like>
                                        }
                                    </Button>
                                    <Box pad={'small'}>
                                        <Text color='#ADADAD'>{comment.likes_count}</Text>
                                    </Box>
                                    <Box width={'xlarge'} align="end" pad='small'>
                                        <Button onClick={() =>{
                                                setReplyingID(comment.id)
                                            }
                                        }>
                                            <Text>Reply</Text>
                                        </Button>
                                    </Box>
                                </Box>

                                { replyingID == comment.id ? 
                                <Box width={"xlarge"} pad={{ horizontal: 'none', vertical: 'small' }}>
                                    <Form replyText={{ replyTextInput: replyText }}
                                            onChange={nextValue => setReplyText(nextValue.replyTextInput)} 
                                            onSubmit={submitReply}>
                                        <Box>
                                            <FormField name="replyTextInput" htmlFor="replyText-input" width={"xlarge"}>
                                                <TextInput id="textinput-id" name="replyTextInput" 
                                                    placeholder="Add a reply" required/>
                                            </FormField>
                                        </Box>
                                        <Box direction="row" justify='end' gap="medium">
                                            <Button type="submit" primary color="#47B0EC" >
                                                <Box pad={"small"} gap="small" direction="row">
                                                    <Text color={"white"}>
                                                        Reply
                                                    </Text>
                                                </Box>
                                            </Button>
                                        </Box>
                                    </Form>
                                </Box>
                                :
                                <Box></Box>

                                }
                                
                                {comment.replies.map(reply => (
                                    <Box elevation="medium" pad='medium' round='small'>
                                        <Text weight={'bold'}> {reply.commenters[0].username} </Text>
                                        <Text key={index}>{reply.commentText}</Text>
                                        <Box direction="row" gap='small' pad={{top: 'small'}}>
                                            <Button onClick={() =>{
                                                    handleCommentLike(reply.id);
                                                }
                                            }>
                                                {
                                                    reply.likes.some(user => user.id === userID) ? <LikeFill size='medium' color="#47B0EC" ></LikeFill>   : <Like size='medium' color="#ADADAD" ></Like>
                                                }
                                            </Button>

                                            <Text  color='#ADADAD'>{reply.likes_count}</Text>
                                        </Box>
                                        
                                    </Box>
                                ))}
                            </Box>
                        </Box>
                    ))}
                    
                </Box>


            </Grommet>
            
        </div>
    )
};
 
export default Article;

