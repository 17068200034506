import React, { useEffect, useState} from "react";
import axios from 'axios';
import { Link, useParams } from "react-router-dom";

import {
    Box,
    Button,
    Form,
    FormField,
    FileInput,
    Grommet,
    Header,
    Text,
    TextArea,
    TextInput,
  } from 'grommet'
  import { Compass } from 'grommet-icons';


const EditArticle = () => {
    const [formData, setFormData] = useState({
        headline: '',
        subtitle: '',
        body: '',
        image_url: '',
    });

    const {id} = useParams();
    const { headline, subtitle, body, likes, image_url } = formData;
    const onChange = e => setFormData({ ...formData, [e.target.name]: e.target.value });
    const token = localStorage.getItem('access_token');
    const [isPublished, setIsPublished] = useState(false);

    const handleImageChange = (e) => {
        let newFormData = { ...formData };
        newFormData["image_url"] = e.target.files[0];
        setFormData(newFormData);
    };


    useEffect(() => {
        if(localStorage.getItem('access_token') === null){                   
            window.location.href = '/login'
        }
        const fetch = async () => {
          try {
            const { data } = await axios.get(`https://leo-api.xyz/api/articles/${id}/`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'application/json',
                  }
                });
                console.log(`published: ${data.published}`);
                setIsPublished(data.published);
            setFormData({
                headline: data.headline,
                subtitle: data.subtitle,
                body: data.body,
                likes: data.likes,
            });
                
            
          } catch (err) {
            console.error(err);
          }
        };

        fetch();

      }, []);


    
    const submit = async e => {

        console.log(`isPublished: ${isPublished}`)

        if(isPublished == false) {

            if (!token) {
                alert('You need to login first');
                return;
            }

            e.preventDefault();
            const newArticle = {
                headline,
                subtitle,
                body,
                likes,
                image_url,
            };

            let form_data = new FormData();
            if (newArticle.image_url)
                form_data.append("image_url", newArticle.image_url, newArticle.image_url.name);
            if (newArticle.likes.length !== 0)
                form_data.append("likes", newArticle.likes);
            form_data.append("headline", newArticle.headline);
            form_data.append("subtitle", newArticle.subtitle);
            form_data.append("body", newArticle.body);


            try {
                const res = await axios.put(`https://leo-api.xyz/api/articles/${id}/`, form_data, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                        "Content-Type": "multipart/form-data",
                    }
                }).then(response => {
                    axios.post(`https://leo-api.xyz/api/articles/${response.data.id}/addAuthor/`,{}, {
                    headers: {
                        'Authorization': 'Bearer ' + localStorage.getItem('access_token'),
                        'Content-Type': 'application/json',
                        'accept': 'application/json'
                    }
                    }, )
                    .then(response => {
                        console.log(response.data.status)
                        console.log(`user: ${response.data.user}`)
                        if (response.data.status === 'added') {
                            console.log("added the author")
                            
                        } else {
                            console.log("already added!")
                        }

                    })
                    .catch(error => {
                        console.log('There was an error adding the author!');
                    });
                });

            } catch (err) {
                console.error(err.response.data);
            }
            window.location.href = `/journalist/home`
        }
        else {
            alert("You cannot edit a published article!")
        }
    };

    return (
        <Grommet>
            <Header
            fill="horizontal"
            pad={{ horizontal: 'medium', vertical: 'small' }}
            elevation='medium'
            background={"white"}
            >
                <Box
                direction="row"
                align="start"
                gap="medium"
                pad={{ vertical: 'small' }}
                >
                    <Link to="/" style={{ textDecoration: 'none' }}>
                        <Box direction="row" gap="xsmall" wrap>
                            <Compass color="#47B0EC" />
                            <Text color="#47B0EC" weight="bold">
                                Atlas
                            </Text>
                            
                            <Text color="text-strong">Journalist Dashboard</Text>
                        </Box>
                    </Link>
                </Box>
            </Header>

            <Box pad={"medium"}>
                <Form onSubmit={submit}>
                    <Box pad={{ horizontal: 'medium', vertical: 'small' }}>
                        <FormField name="Title" label="Title" htmlFor="Title" width={"medium"}>
                            <TextInput id="textinput-id" name="headline" value={headline}
                            onChange={onChange} required/>
                        </FormField>

                        <FormField name="subtitle" htmlFor="subtitle" label="Subtitle" width={"medium"}>
                            <TextInput id="textinput-subtitle" name="subtitle" value={subtitle}
                            onChange={onChange} required/>
                        </FormField>

                        <FormField name="Body" label="Body" htmlFor="Body">
                            <Box height="large">
                                <TextArea id="textinput-body" name="body" value={body}
                                onChange={onChange} required fill/>
                            </Box>
                        </FormField>
                        <FormField>
                            <FileInput
                                name="image_url"
                                onChange={(e) => {
                                    handleImageChange(e);
                                }}
                                messages={{
                                    dropPrompt: 'Upload cover image',
                                    browse: 'browse',
                                  }}
                                />
                        </FormField>

                    </Box>
                    <Box direction="row" justify='center' gap="medium">
                        <Button type="submit" primary color="#47B0EC" >
                            <Box pad={"small"} gap="small" direction="row">
                                <Text color={"white"}>
                                    Submit
                                </Text>
                            </Box>
                        </Button>
                    </Box>
                </Form>
            </Box>


        </Grommet>

    );
};

export default EditArticle;