import React, { useContext, useEffect, useState } from "react";
import { ReaderHeader } from "./ReaderHeader";
import { useParams } from "react-router-dom";
import axios from 'axios'
import styles from '../journalist_components/Style.module.css'
import {
    Box,
    Grommet,
    Heading,
    Image,
    Paragraph, 
    ResponsiveContext,
    Text,
  } from 'grommet'

  import { StatusGood } from "grommet-icons";
  import ReactMarkdown from 'react-markdown';
  import { Link } from "react-router-dom";

const ReaderArticle = () => {
    const size = useContext(ResponsiveContext)
    const {id} = useParams();
    const [hasEndorsed, SetHasEndorsed] = useState(false);
    const [article, SetArticle] = useState({});
    const [author, SetAuthor] = useState({});
    const [userID, SetUserID] = useState();
    const [buttonText, setButtonText] = useState(
        `Endorsements`
    )

    const token = localStorage.getItem('access_token');

    useEffect(() => {
        const fetch = async () => {
          try {
            const { data } = await axios.get(`https://leo-api.xyz/api/articles/${id}/`, {
                headers: {
                    'Content-Type': 'application/json',
                  }
                });
            SetArticle(data);
            console.log(`article ${data}`)
            setButtonText(`${data.likes_count} Endorsements`)

            
            axios.get(`https://leo-api.xyz/api/users/${data.authors}/`, {
                headers: {
                    'Content-Type': 'application/json',
                  }
                }).then (response => {
                    SetAuthor(response.data)
                })
                
            
          } catch (err) {
            console.error(err);
          }
        };

        fetch();

      }, []);


    console.log(article)

    return (
        <div>
            <ReaderHeader />
            <Grommet>

                <Box align="center" pad={{ horizontal: 'large', vertical: 'small' }}>
                    <Heading weight='bold' textAlign="center" level={2} pad="none">
                        {article.headline}
                    </Heading>
                    <Box pad={{ bottom:'medium'}} width={'large'}>
                        <Text size="large" textAlign="center" weight={'lighter'}>
                            {article.subtitle}
                        </Text>
                    </Box>
                    {
                        author.first_name ? 
                        <Box direction="row" gap="small"> 
                            <Text>
                                By: {author.first_name} {author.last_name}
                            </Text> 
                            <StatusGood color="blue"/>
                        </Box>
                        :<Text>
                            By: {author.username}  
                        </Text>
                    }
                    
                    <Box pad={{ top:'medium'}} width={'large'}>
                        <Text size="small" textAlign="center" color="dark-6">
                            {new Date(article.written_on).toLocaleDateString('en-US', {
                                year: 'numeric',
                                month: 'long',
                                day: 'numeric'
                            })}
                        </Text>
                    </Box>

                    <Box width={"xlarge"} pad={"medium"}> 
                        <Image fill src={article.image_url}/>
                    </Box>

                    <ArticleBody article={article.body} />

                    <Box width={"large"} border="bottom"></Box>
                    <Box pad="small"></Box>
                    
                </Box>
                
            </Grommet>
            
        </div>
    )
};

const ArticleBody = ({ article }) => {
    return (
      <Box width="xlarge">
        <ReactMarkdown>
          {article}
        </ReactMarkdown>
      </Box>
    );
  };
 
export default ReaderArticle;

