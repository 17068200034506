import axios from "axios";
import {useState} from "react";
import { Link } from "react-router-dom";

import {
  Box,
  Button,
  Form,
  FormField,
  Grid,
  Grommet,
  Heading,
  Header,
  Image,
  Text,
  TextInput,
} from 'grommet'
import { Compass, LinkNext} from 'grommet-icons';
import logo from '../images/logo.png'

export const Login = () => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');
    // Create the submit method.
    const submit = async e => {
         e.preventDefault();
         const user = {
               username: username,
               password: password
              };
         // Create the POST requuest
         try {
          const {data} = await                                                                            
                          axios.post(
                              'https://leo-api.xyz/token/',
                              user,
                              { headers: {
                                  'Authorization': 'Bearer ' + localStorage.getItem('access_token'),
                                  'Content-Type': 'application/json',
                                  'accept': 'application/json'
                              } },
                              { withCredentials: true }
                      );
                      // Initialize the access & refresh token in localstorage.      
                      localStorage.clear();
                      localStorage.setItem('access_token', data.access);
                      localStorage.setItem('refresh_token', data.refresh);
                      axios.defaults.headers.common['Authorization'] = 
                                                      `Bearer ${data['access']}`;
                      window.location.href = '/journalist/home'
            }
            catch (err){
              // Check for error response and set error message
              setError('Incorrect credentials');

            }
   }
   return(

    <Grommet>
      <Header
      fill="horizontal"
      pad={{ horizontal: 'medium', vertical: 'small' }}
      elevation='medium'
      background={"white"}
      >
          <Box
          direction="row"
          align="start"
          gap="medium"
          pad={{ vertical: 'none' }}
          >
            <Box direction="row" gap="xsmall" wrap align="center">
                <Image src={logo} width="40px"/>
                <Text color="text-strong" weight="bold">
                    Leo
                </Text>
                
                <Text color="text-strong">Journalist Dashboard</Text>
            </Box>
          </Box>
      </Header>
      
      
      <Grid 
        rows={['auto']}
        columns={['14/20', '6/20']}
        areas={[
          {name: 'left', start: [0, 0], end: [0, 0]}, 
          {name: 'right', start: [1, 0], end: [1, 0]}
        ]}
        >
        <Box gridArea="left">
          <Box background="#47B0EC" height="large" pad={{ top: 'none', horizontal: 'medium', bottom:'large'}}>
            <Box height={"xsmall"} />
            <Heading color="white" weight="bold" level={1}>
              Hello!
            </Heading>
      
            <Heading color="white" weight="bold" level={2}>
              Please sign in to continue
            </Heading>

            <Heading color="white" weight="bold" level={3}>
              We requre all article contributions to come from registered accounts to reduce spam
            </Heading>
          </Box>

        </Box>
        <Box gridArea="right">
          <Box height={"xsmall"}></Box>
          <Box flex="grow" elevation='medium' pad={{ top: 'xlarge', horizontal:'small'}}>
            <Form onSubmit={submit}>
              <FormField name="Username" htmlFor="textinput-id" label="Username" >
                <TextInput id="textinput-id" name="username" value={username}
                onChange={event => setUsername(event.target.value)}/>
              </FormField>
              <FormField name="Password" label="Password" htmlFor="password-example">
                <TextInput id="textinput-id" name="password" value={password}
                onChange={event => setPassword(event.target.value)} type="password"/>
              </FormField>
              <Box direction="row" gap="medium">
                <Button type="submit" primary color="#47B0EC" >
                  <Box pad={"small"} gap="small" direction="row">
                    <Text color={"white"}>
                      Submit
                    </Text>
                    <LinkNext color="white"/>
                  </Box>
                </Button>
              </Box>
            </Form>
            {error && <p style={{ color: 'red' }}>{error}</p>}
            { 
            <Box pad={{ top:'small' }}>
              <Link to={`/register/`} style={{ textDecoration: 'none' }}>
                <Text>
                  Don't have an account? Sign up here!
                </Text>
              </Link>
            </Box>
            }
          </Box>

          
          
        </Box>
      </Grid>
      

      
    </Grommet>
    )
}