import React, { useEffect, useState} from "react";
import axios from 'axios';
import { Link } from "react-router-dom";

import {
    Box,
    Button,
    FileInput,
    Form,
    FormField,
    Grommet,
    Select,
    Text,
    TextArea,
    TextInput,
  } from 'grommet'
  import { Compass } from 'grommet-icons';
import { JournalistHeader } from "./JournalistHeader";


const NewArticle = () => {
    const [formData, setFormData] = useState({
        headline: '',
        subtitle: '',
        body: '',
        image_url: '',
    });
    const { headline, subtitle, body, image_url } = formData;
    const onChange = e => setFormData({ ...formData, [e.target.name]: e.target.value });


    const token = localStorage.getItem('access_token');
    const [showImageError, setShowImageError] = useState(false);
    const [showSourceTitleError, setShowSourceTitleError] = useState(false);
    const [showSourceAuthorError, setShowSourceAuthorError] = useState(false);
    const [file, setFile] = useState(null);
    const [sources, setSources] = useState([{ source_title: '', source_author: '', source_URL: '' }]);

    const handleImageChange = (e) => {
        let newFormData = { ...formData };
        newFormData["image_url"] = e.target.files[0];
        setFormData(newFormData);
    };

    const handleInputChange = (index, field, value) => { // for sources
        const newSources = [...sources];
        newSources[index][field] = value;
        setSources(newSources);
    };

    const addSource = () => { // for sources
        setSources([...sources, { source_title: '', source_author: '', source_URL: '' }]);
        console.log('Number of sources:', sources.length + 1); // Log the new size
    };

    const submit = async e => {

        //error checking
        if (!token) {
            alert('You need to login first');
            return;
        }
        if (!file) {
            console.log("No image!");
            setShowImageError(true);
            return;
        }
        if(sources[0].source_title.length === 0) {
            setShowSourceTitleError(true);
            return;
        }
        if(sources[0].source_author.length === 0) {
            setShowSourceAuthorError(true);
            return;
        }

        e.preventDefault();
        const newArticle = {
            headline,
            subtitle,
            body,
            image_url,
        };

        let form_data = new FormData();
        if (newArticle.image_url)
            form_data.append("image_url", newArticle.image_url);
        form_data.append("headline", newArticle.headline);
        form_data.append("subtitle", newArticle.subtitle);
        form_data.append("body", newArticle.body);


        console.log(`newArticle headline: ${newArticle.headline}`);
        console.log(`formData headline: ${form_data.headline}`);

        try {
            const res = await axios.post('https://leo-api.xyz/api/articles/', form_data, {
                headers: {
                    Authorization: `Bearer ${token}`,
                    "Content-Type": "multipart/form-data",
                  }
            })

            console.log("About to add author!")
            console.log(`response id: ${res.data.id}`)
            
            const res2 = await axios.post(`https://leo-api.xyz/api/articles/${res.data.id}/addAuthor/`,{}, {
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('access_token'),
                'Content-Type': 'application/json',
                'accept': 'application/json'
            }
            }, )

            //NOW ADDING SOURCES!
            console.log(`Sources size: ${sources.length} `)
            
            sources.forEach(async (source) => {

                let source_form_data = new FormData();
                source_form_data.append("article", res.data.id);
                source_form_data.append("title", source.source_title);
                source_form_data.append("author", source.source_author);
                source_form_data.append("URL", source.source_URL);

                const res3 = await axios.post('https://leo-api.xyz/api/source/', source_form_data, {
                headers: {
                    Authorization: `Bearer ${token}`,
                    "Content-Type": "multipart/form-data",
                  }
            })
            console.log("submitted!")

            window.location.href = `/journalist/home`

            });


            
        } catch (err) {
            console.error(err.response.data);
        }
        };

    return (
        <Grommet>
            <JournalistHeader />

            <Box pad={"medium"}>
                <Form onSubmit={submit}>
                    <Box pad={{ horizontal: 'medium', vertical: 'small' }}>
                        <FormField name="Title" label="Title *" htmlFor="Title" width={"medium"}>
                            <TextInput id="textinput-id" name="headline" value={headline}
                            onChange={onChange} required/>
                        </FormField>

                        <FormField name="subtitle" htmlFor="subtitle" label="Subtitle *" width={"medium"}>
                            <TextInput id="textinput-subtitle" name="subtitle" value={subtitle}
                            onChange={onChange} required/>
                        </FormField>

                        <FormField name="Body" label="Body *" htmlFor="Body">
                            <Box height="auto" >
                                <TextArea id="textinput-body" name="body" value={body}
                                onChange={onChange} required fill rows={25}/>
                            </Box>
                        </FormField>
                        <FormField label="Cover image *">
                            <FileInput
                                name="image_url"
                                onChange={(e) => {
                                    const fileList = e.target.files;
                                    if (fileList.length > 0) {
                                        setFile(fileList[0]);
                                        setShowImageError(false);
                                    }
                                    else {
                                        setFile(null);
                                    }
                                    handleImageChange(e);
                                }}
                                messages={{
                                    dropPrompt: 'Upload cover image',
                                    browse: 'browse',
                                  }}
                            />
                        </FormField>
                        {showImageError && (
                        <Box>
                            <Text color="status-critical">Cover image is required</Text>
                        </Box>
                    )}

                    {/* Add Source Fields */}
                    {sources.map((source, index) => (
                        <Box key={index} margin={{ bottom: 'xlarge' }}>
                            {/* if index === 0 then we mark the source info as required */}
                            {index === 0 ? 
                                <div>
                                    <FormField label={`Source ${index + 1} Title *`}>
                                        <TextInput
                                            value={source.title}
                                            onChange={(e) => {
                                            if(e.target.value.length > 0 ) {
                                                setShowSourceTitleError(false);
                                            }
                                            handleInputChange(index, 'source_title', e.target.value)
                                        }}
                                        />
                                    </FormField>
                                    {showSourceTitleError && index === 0 && (
                                        <Box>
                                            <Text color="status-critical">The first source's title is required</Text>
                                        </Box>
                                    )}
                                    <FormField label={`Source ${index + 1} Author *`}>
                                        <TextInput
                                            value={source.author}
                                            onChange={(e) => {
                                            if(e.target.value.length > 0 ) {
                                                setShowSourceAuthorError(false);
                                            }
                                            handleInputChange(index, 'source_author', e.target.value)
                                        }}
                                        />
                                    </FormField>
                                    {showSourceAuthorError && index === 0 && (
                                        <Box>
                                            <Text color="status-critical">The first source's author is required</Text>
                                        </Box>
                                    )}
                                </div>
                                :
                                <div>
                                    <FormField label={`Source ${index + 1} Title`}>
                                        <TextInput
                                            value={source.title}
                                            onChange={(e) => {
                                            if(e.target.value.length > 0 ) {
                                                setShowSourceTitleError(false);
                                            }
                                            handleInputChange(index, 'source_title', e.target.value)
                                        }}
                                        />
                                    </FormField>
                                    {showSourceTitleError && index === 0 && (
                                        <Box>
                                            <Text color="status-critical">The first source's title is required</Text>
                                        </Box>
                                    )}
                                    <FormField label={`Source ${index + 1} Author`}>
                                        <TextInput
                                            value={source.author}
                                            onChange={(e) => {
                                            if(e.target.value.length > 0 ) {
                                                setShowSourceAuthorError(false);
                                            }
                                            handleInputChange(index, 'source_author', e.target.value)
                                        }}
                                        />
                                    </FormField>
                                    {showSourceAuthorError && index === 0 && (
                                        <Box>
                                            <Text color="status-critical">The first source's author is required</Text>
                                        </Box>
                                    )}
                                </div>
                        
                        
                        }

                            <FormField label={`Source ${index + 1} URL`}>
                                <TextInput
                                value={source.URL}
                                onChange={(e) => handleInputChange(index, 'source_URL', e.target.value)}
                                />
                            </FormField>
                        </Box>
                    ))}
                    <Button type="button" onClick={addSource} label="Add Another Source" />
                    

                    </Box>
                    <Box direction="row" justify='center' gap="medium">
                        <Button type="submit" primary color="#47B0EC" >
                            <Box pad={"small"} gap="small" direction="row">
                                <Text color={"white"}>
                                    Submit Article
                                </Text>
                            </Box>
                        </Button>
                    </Box>
                </Form>
            </Box>


        </Grommet>

    );
};

export default NewArticle;