import React from 'react';
import { Box, Text, Heading, Paragraph, Image } from 'grommet';
import { Organization } from 'grommet-icons'; // You can use any other icon or your logo
import logo from '../images/logo.png'
import { ReaderHeader } from './ReaderHeader';

const AboutPage = () => {
  return (
    <div>
    <ReaderHeader />

    <Box pad="medium" align="center" direction='row' justify='between'>
        <Image
          src={logo}
          alt="Leo Logo"
          
        />
        <Box>
          <Heading level="1" margin="small">
            About Us
          </Heading>
          <Heading level="2" margin="small">
            Welcome to Leo News, where we work everyday to restore faith in American media
        </Heading>
        </Box>

    </Box>

    <Box pad="large" align="center" direction='row' justify='between' background="light-2">
        <Box pad={'small'}>
          <Heading level="2" >
              Motivation
            </Heading>
            <Box width={'large'}>
            <Paragraph size='large' fill>
              We believe the toxicity present in American discourse is a result of the media 
              being controlled by an increasingly small number of people, all of whom are driven entirely by
              a desire to increase profitability
          </Paragraph>
          </Box>
        </Box>

        <Box pad={'small'}>
          <Heading level="2" >
              Goal
            </Heading>
            <Box width={'large'}>
              <Paragraph size='large' fill>
                Our goal is to put the power of the media in the hands of the American people. We believe this will
                reduce the toxicity present in current American discourse and restore Americans' faith in their media
            </Paragraph>
          </Box>
        </Box>

    </Box>
    <Box fill align="center" justify="center">
      <Box align='center' pad={'large'} width={'xlarge'}>
        <Heading level="2" margin="small">
            How it works
          </Heading>
          <Paragraph size='large' fill>
            At Leo, we operate using an open-source model. This means that anyone can write articles. All written articles are 
            peer reviewed, and the top reviewed articles are published for our readers. We built a platform to enable this process
            because we believe that the power of the press should lie in the hands of the people.
        </Paragraph>
      </Box>
    </Box>

    <Box justify="center">
      <Box pad={'large'} width={'xlarge'}>
        <Heading level="4">
            Contact Us
          </Heading>
          <Paragraph size='medium' fill>
            Email: will@leo-news.org
        </Paragraph>
      </Box>
    </Box>

    
    </div>
  );
};

export default AboutPage;
