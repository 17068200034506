import {
    Box,
    Grommet,
    Header, 
    Image,
    Text,
  } from 'grommet'

  import axios from 'axios'
  import { Compass, Add, User } from 'grommet-icons';
  import { Link } from "react-router-dom";
  import React, { useState, useEffect} from 'react';
  import logo from '../images/logo.png'

export const JournalistHeader = () => {
    const [isAuth, setIsAuth] = useState(false);
    const [userID, SetUserID] = useState({})
    const token = localStorage.getItem('access_token');

    useEffect(() => {

        if(!token){                   
            window.location.href = '/login'
        }
        const fetch = async () => {
          try {
            const { data } = await axios.get(`https://leo-api.xyz/api/user/`, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('access_token')}`,
                    'Content-Type': 'application/json',
                  }
                });
                SetUserID(data.user)

          } catch (err) {
            console.error(err);
            }
        };

        fetch();

      }, []);

    
    return (
        <Grommet>
            <Header
            sticky="scrollup"
            fill="horizontal"
            pad={{ horizontal: 'medium', vertical: 'small' }}
            elevation='medium'
            >
                <Box
                direction="row"
                align="start"
                gap="medium"
                pad={{ vertical: 'none' }}
                >
                    <Link to="/journalist/home" style={{ textDecoration: 'none' }}>
                        <Box direction="row" gap="xsmall" wrap align='center'>
                            <Image src={logo} width="40px"/>
                            <Text color="#47B0EC" weight="bold">
                                Leo
                            </Text>
                            <Text color="text-strong">Journalist Dashboard</Text>
                        </Box>
                    </Link>
                </Box>
                <Box direction='row' gap={"large"} >
                    <Link to={"/newArticle"} style={{ textDecoration: 'none' }}>
                        <Box direction='row' gap={"small"} >
                            <Add />
                        </Box>
                    </Link>
                    <Link to={`/user/${userID}/`} style={{ textDecoration: 'none' }}>
                        <Box direction='row' gap={"small"} >
                            <User /> 
                        </Box>
                    </Link>
                    <Link to="/" style={{ textDecoration: 'none' }}>
                        <Text color="text-strong">Published Articles</Text> 
                    </Link> 
                    <Link to="/logout" style={{ textDecoration: 'none' }}>
                        <Text color="text-strong">Logout</Text> 
                    </Link> 
                </Box>
            </Header>
      </Grommet>
    );
  };