import React, { useState } from 'react';
import axios from 'axios';
import { Link } from "react-router-dom";
import { motion } from "framer-motion"

import {
    Box,
    Button,
    Form,
    FormField,
    Grommet,
    Heading,
    Header,
    Image,
    Text,
    TextInput,
  } from 'grommet'
  import { Compass, LinkNext} from 'grommet-icons';
  import logo from '../images/logo.png'


const Register = () => {
    const [formData, setFormData] = useState({
        username: '',
        email: '',
        password: ''
    });
    const [isMoved, setIsMoved] = useState(false);

    const [error, setError] = useState('');

    const { username, email, password } = formData;

    const onChange = e => setFormData({ ...formData, [e.target.name]: e.target.value });

    const submit = async e => {
        e.preventDefault();
        const newUser = {
            username,
            email,
            password
        };

        try {
            console.log("Registering new user!")
           
            const res = await axios.post('https://leo-api.xyz/register/', newUser);
            console.log(res)
            if(res.response) {
                if(res.response.data.username[0] === 'A user with that username already exists.') {
                    console.log("Username already exists")
                    setError("This username is already taken");
                    return;
                }
            }
            setIsMoved(true);
            
        } catch (err) {
            console.log("FAILED 1!")
        }
    };

    return (
        <Grommet>
            <Header
            fill="horizontal"
            pad={{ horizontal: 'medium', vertical: 'small' }}
            elevation='medium'
            background={"white"}
            >
                <Box
                direction="row"
                align="start"
                gap="medium"
                pad={{ vertical: 'small' }}
                >
                    <Box direction="row" gap="xsmall" wrap align='center'>
                            <Image src={logo} width="40px"/>
                            <Text color="#47B0EC" weight="bold">
                                Leo
                            </Text>
                            <Text color="text-strong">Journalist Dashboard</Text>
                        </Box>
                </Box>
            </Header>
            

                <Box align='center'>
                    <Heading weight="bold" level={1} color="#47B0EC">
                        {!isMoved ? ("Welcome !") : ("Success!")
                        }

                    </Heading>
            
                    <Heading weight="bold" level={2} color="#47B0EC">
                        {!isMoved ? ("Enter your info to continue") : ("")}
                    </Heading>
                </Box>

                <Box direction='row' gap='medium' justify='center'>
                <motion.div animate={{ x: isMoved? -170 : 0 }} transition={{ type: 'spring', stiffness: 50 }}>

                    <Box width="medium" elevation='medium' pad={{ top: 'small', bottom: 'small', horizontal:'small'}}>
                        <Form onSubmit={submit}>

                        <FormField name="Email" label="Email" htmlFor="email">
                            <TextInput disabled={isMoved} id="textinput-id" name="email" value={email}
                            onChange={onChange} type='email' required/>
                        </FormField>

                        <FormField name="Username" htmlFor="textinput-id" label="Username" >
                            <TextInput disabled={isMoved} id="textinput-id" name="username" value={username}
                                onChange={onChange} required/>
                        </FormField>

                        <FormField name="Password" label="Password" htmlFor="password">
                            <TextInput disabled={isMoved} id="textinput-id" name="password" value={password}
                            onChange={onChange} type="password" required/>
                        </FormField>

                        {!isMoved && 
                        <Box direction="row" justify='center' gap="medium">
                            <Button type="submit" primary color="#47B0EC" >
                                <Box pad={"small"} gap="small" direction="row">
                                    <Text color={"white"}>
                                        Submit
                                    </Text>
                                </Box>
                            </Button>
                        </Box>
                        }
                        {error && <p style={{ color: 'red' }}>{error}</p>


                        }
                        </Form>
                </Box>
                
            </motion.div>

            {isMoved && 
            <Box justify='center'>
                <Link to={`/login`} style={{ textDecoration: 'none' }}>
                    <Button  primary color="#47B0EC" >
                        <Box pad={"small"} gap="small" direction="row">
                            <Text color={"white"}>
                                Continue
                            </Text>
                            <LinkNext color="white"/>
                        </Box>
                    </Button>
                </Link>
            </Box>
        }
        </Box>
    </Grommet>

    );
};

export default Register;