import {
    Box,
    Grommet,
    Header, 
    Image,
    Text,
  } from 'grommet'

  import axios from 'axios'
  import { Compass, Add, User } from 'grommet-icons';
  import { Link } from "react-router-dom";
  import React, { useState, useEffect} from 'react';
  import logo from '../images/logo.png'


export const ReaderHeader = () => {

    
    return (
        <Grommet>
            <Header
            sticky="scrollup"
            fill="horizontal"
            pad={{ horizontal: 'medium', vertical: 'small' }}
            elevation='medium'
            >
                <Box
                direction="row"
                align="start"
                gap="medium"
                pad={{ vertical: 'none' }}
                >
                    <Link to="/reader/home" style={{ textDecoration: 'none' }}>
                        <Box direction="row" gap="xsmall" wrap align="center">
                            <Image src={logo} width="40px"/>
                            <Text color="#47B0EC" size='large' weight="bold" alignSelf="center">
                                Leo News
                            </Text>
                        </Box>
                    </Link>
                </Box>
                <Box direction='row' gap={"large"} >
                    <Link to={"/about"} style={{ textDecoration: 'none' }}>
                        <Box direction='row' gap={"small"} >
                            <Text color="text-strong" >About</Text> 
                        </Box>
                    </Link>
                    <Link to={"/login"} style={{ textDecoration: 'none' }}>
                        <Box direction='row' gap={"small"} >
                            <Text color="text-strong" >Write an article</Text> 
                        </Box>
                    </Link>
                </Box>

                
            </Header>
      </Grommet>
    );
  };